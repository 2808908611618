export const initializeHotjar = async (hjid: number, hjsv: number = 6) => {
  const h = window as any;
  const o = document;
  const t = 'https://static.hotjar.com/c/hotjar-';
  const j = '.js?sv=';

  h.hj =
    h.hj ||
    function () {
      (h.hj.q = h.hj.q || []).push(arguments);
    };
  h._hjSettings = { hjid: hjid, hjsv: hjsv };
  const a = o.getElementsByTagName('head')[0];
  const r = o.createElement('script');
  r.async = true;
  r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
  a.appendChild(r);
};
